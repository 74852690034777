<template>
  <BCard body-class="border rounded-lg">
    <div class="flex items-center justify-between">
      <div class="text-black font-semibold m-0 text-2xl">
        Kategori Pengeluaran
      </div>
      <DateRangePicker
        ref="picker"
        v-model="dateRange"
        :locale-data="locale"
        :ranges="ranges"
        :opens="'left'"
      >
        <template v-slot:input="picker">
          <div class="d-flex justify-content-between align-items-center w-100">
            <div class="mr-1">
              <span
                v-if="
                  YEAR_MONTH_DAY(picker.startDate) ===
                    YEAR_MONTH_DAY(firstDateOfMonth) &&
                    YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(today)
                "
                class="text-[#828282]"
              >
                Bulan ini
              </span>
              <span
                v-else-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(new Date(today.getFullYear(), today.getMonth() - 1, 1))"
                class="text-[#828282]"
              >
                Bulan lalu
              </span>
              <span
                v-else-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last60)"
                class="text-[#828282]"
              >
                2 Bulan terakhir
              </span>
              <span
                v-else-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last90)"
                class="text-[#828282]"
              >
                3 Bulan terakhir
              </span>
              <span
                v-else
                class="text-[#828282]"
              >
                {{ DAY_MONTH_YEAR(picker.startDate) }} - {{ DAY_MONTH_YEAR(picker.endDate) }}
              </span>
            </div>
            <BImg src="https://storage.googleapis.com/komerce/assets/icons/calendar.png" />
          </div>
        </template>
      </DateRangePicker>
    </div>
    <BOverlay
      :show="isLoading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
    >
      <BRow class="items-center yuhu">
        <BCol
          lg="6"
        >
          <ApexChart
            ref="chart"
            type="donut"
            :options="options"
            :series="series"
            width="350"
          />
        </BCol>
        <BCol
          lg="6"
          class="text-end"
        >
          <div class="font-normal text-lg text-[#626262]">
            Pengeluaran
          </div>
          <div class="font-bold text-2xl text-black">
            {{ IDR(data.total) }}
          </div>
        </BCol>
      </BRow>
    </BOverlay>
  </BCard>
</template>

<script>
import ApexChart from 'vue-apexcharts'
import { newAxiosIns } from '@/libs/axios'
import DateRangePicker from 'vue2-daterange-picker'
import {
  firstDateOfMonth, last60, last90, today,
} from '@/store/helpers'
import {
  LABELDAY, LABELMONTH, YEAR_MONTH_DAY, DAY_MONTH_YEAR,
} from '@/libs/formatDate'
import { IDR } from '@/libs/currency'
import { chartSpendingCategoryOptions } from '../config'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: { ApexChart, DateRangePicker },
  data() {
    return {
      isLoading: false,
      series: [],
      options: chartSpendingCategoryOptions,
      firstDateOfMonth,
      today,
      last60,
      last90,
      YEAR_MONTH_DAY,
      DAY_MONTH_YEAR,
      IDR,
      data: {
        total: 0,
        category: {},
      },
      dateRange: {
        startDate: firstDateOfMonth,
        endDate: today,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'Bulan ini': [firstDateOfMonth, today],
        'Bulan lalu ': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        '2 Bulan terakhir': [last60, today],
        '3 Bulan terakhir': [last90, today],
      },
    }
  },
  watch: {
    dateRange: {
      handler() {
        this.getSpendingCategory()
      },
    },
  },
  mounted() {
    this.getSpendingCategory()
  },
  methods: {
    async getSpendingCategory() {
      this.isLoading = true
      const url = '/komcards/api/v1/user/statistics/expense'
      const params = {
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
      }
      await newAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.isLoading = false
          this.data.total = data.total_expense

          const transformerData = Object.keys(data.category).map(key => {
            const newName = key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
            return {
              name: newName,
              data: data.category[key].percentage,
              amount: IDR(data.category[key].amount),
            }
          })

          let allZero = true

          // eslint-disable-next-line no-restricted-syntax
          for (const key in data.category) {
            if (data.category[key].percentage !== 0) {
              allZero = false
              break
            }
          }

          if (allZero) {
            this.options = {
              ...this.options,
              labels: ['Belum ada data'],
              colors: ['#D2B48C'],
            }
            this.series = [100]
          } else {
            this.series = transformerData.map(item => item.data)
            this.options = {
              ...this.options,
              labels: transformerData.map(item => item.name),
              colors: ['#08A0F7', '#333333', '#EA4335', '#D2B48C'],
              xaxis: {
                ...this.options.xaxis,
                categories: transformerData.map(item => item.amount),
              },
            }
          }
        })
        .catch(() => {
          this.isLoading = false
          this.$toast_error({ message: 'Gagal memuat data kategori pengeluaran. Silahkan coba lagi.' })
        })
    },
  },
}
</script>
<style scoped>
.yuhu {
  background-image: url('https://storage.googleapis.com/komerce/assets/elements/BGCard-NoBorder-informationspending.svg ');
  background-repeat: no-repeat;
  background-position: right 0px bottom 0px;
  background-size: 550px;
}
</style>
