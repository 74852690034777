<template>
  <div>
    <BModal
      id="modal-transfer"
      ref="modal-transfer"
      hide-footer
      hide-header
      centered
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <ValidationObserver #default="{ invalid }">
        <BRow class="justify-center my-1 flex flex-column items-center gap-1">
          <BCol class="flex flex-col justify-center text-center mb-1">
            <div class="text-[#333333] font-semibold text-2xl mb-[5px]">
              Transfer Saldo Kartu
            </div>
            <div class="text-[#333333] text-lg">
              Pindahkan saldo antar kartu dengan
              cepat dan mudah
            </div>
          </BCol>
          <BCol>
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <span class="font-medium text-base">Kartu Tujuan</span>
                  <span class="text-primary">*</span>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Kartu Tujuan"
                rules="required"
              >
                <VSelect
                  v-model="cardTransferId"
                  placeholder="Pilih kartu"
                  label="card_name"
                  :options="optionsCards"
                  :reduce="option => option.value"
                  :loading="isLoading"
                >
                  <template #no-options>
                    Tidak ada kartu
                  </template>
                  <template #option="{ card_name }">
                    <div class="flex items center gap-2">
                      <img
                        class="w-6"
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/card.svg"
                        alt="komcard"
                      >
                      <span class="text-black">{{ card_name }}</span>
                    </div>
                  </template>
                </VSelect>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <span class="font-medium text-base">Nominal</span>
                  <span class="text-primary">*</span>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Nominal"
                :rules="{ required: true, minTransfer: 'transfer', maxTransfer: infoBalance.effective_balance }"
              >
                <BFormInput
                  v-model="amount"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Masukkan nominal transfer"
                  @keypress="isNumber($event)"
                  @input="formatCurrency"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol
            class="border rounded-lg p-1 my-2"
            style="border-color: #E2E2E2 !important"
          >
            <BPopover
              triggers="hover"
              target="popover-saldo-pending"
              placement="topRight"
            >
              Saldo ini belum dapat ditransfer, namun bisa digunakan untuk transaksi. Pihak penyedia layanan segera memprosesnya kembali ke kartu debit kamu
            </BPopover>
            <div class="flex items-center justify-between font-semibold text-black ml-1">
              <div>Saldo Komcards</div>
              <div>{{ IDR(infoBalance.balance) }}</div>
            </div>
            <div class="flex items-center justify-between  text-black ml-2">
              <div>Voucher Iklan</div>
              <div class="text-primary">
                {{ IDR(infoBalance.voucher_balance) }}
              </div>
            </div>
            <div class="flex items-center justify-between text-black ml-2">
              <div class="flex items-center gap-3">
                <div>Saldo Pending</div>
                <img
                  id="popover-saldo-pending"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt="Komerce"
                  width="20"
                  class="cursor-pointer"
                >
              </div>
              <div class="text-primary">
                {{ IDR(infoBalance.hold_balance) }}
              </div>
            </div>
            <div class="flex items-center justify-between  text-black ml-2">
              <div>Saldo Mengendap</div>
              <div class="text-primary">
                {{ IDR(infoBalance.balance_settle) }}
              </div>
            </div>
            <div class="flex items-center justify-between font-semibold text-black mt-1 border-[#FEF2CD] bg-[#FFF8E1] rounded-lg p-1">
              <div>Saldo yang bisa ditransfer</div>
              <div class="font-semibold">
                {{ IDR(infoBalance.effective_balance) }}
              </div>
            </div>
          </BCol>
        </BRow>
        <BRow class="mb-1">
          <BCol cols="6">
            <BButton
              variant="outline-primary"
              block
              @click="
                $bvModal.hide('modal-transfer')
                cardTransferId = null
                amount = null
              "
            >
              Batal
            </BButton>
          </BCol>
          <BCol cols="6">
            <BButton
              :disabled="invalid"
              :variant="invalid ? 'secondary' : 'primary'"
              type="submit"
              block
              @click.prevent="handleTransfer"
            >
              Transfer Saldo
            </BButton>
          </BCol>
        </BRow>
      </ValidationObserver>
    </BModal>

    <BModal
      id="modal-confirmation-transfer"
      ref="modal-confirmation-transfer"
      no-stacking
      centered
      hide-footer
      hide-header
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="md"
    >
      <BRow class="justify-center my-1 flex flex-column">
        <BCol class="d-flex justify-content-center">
          <div class="text-black font-semibold text-xl">
            Verifikasi PIN
          </div>
        </BCol>
        <BCol class="d-flex justify-content-center mt-1">
          <div class="text-black text-md text-center">
            Mohon verifikasi identitias kamu dengan memasukkan PIN
          </div>
        </BCol>
        <BCol class="d-flex justify-content-center mt-2 mb-1">
          <CodeInput
            :style="isVisibility ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
            :loading="false"
            class="input"
            :type="visibilityPin"
            :field-width="40"
            @change="handleChangePin"
          />
        </BCol>
        <BCol class="d-flex justify-content-center">
          <BButton
            variant="flat-primary"
            class="btn-icon"
            @click="visiblePin"
          >
            {{ isVisibility ? 'Tampilkan' : 'Sembunyikan' }}
          </BButton>
        </BCol>
        <BCol
          class="d-flex my-1 justify-content-center font-bold text-primary cursor-pointer"
          @click="$router.push('/setting-kompship/pin')"
        >
          Lupa PIN?
        </BCol>
        <BCol class="d-flex justify-content-center text-primary">
          <small class="text-danger text-center">{{ errorMessage }}</small>
        </BCol>
        <BCol class="d-flex justify-content-center mt-1 pb-2 gap-4">
          <BButton
            variant="outline-primary"
            @click="handleBackToTransfer"
          >
            Kembali
          </BButton>
          <BButton
            :variant="pin.length < 6 || isLoadingTransfer ? 'secondary' : 'primary'"
            :disabled="pin.length < 6 || isLoadingTransfer"
            @click.prevent="onSubmit"
          >
            <BSpinner
              v-if="isLoadingTransfer"
              variant="light"
              small
            />
            Submit
          </BButton>
        </BCol>
      </BRow>
    </BModal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import VSelect from 'vue-select'
import { required } from '@validations'
import { newAxiosIns } from '@/libs/axios'
import { IDRWithoutLbl, IDR } from '@/libs/currency'
import CodeInput from 'vue-verification-code-input'
import { isNumber } from '@/libs/helpers'

extend('minTransfer', {
  validate: value => {
    const minTransfer = 10000
    const amount = parseFloat(value.replace(/[Rp.p]/g, ''))
    return amount >= minTransfer
  },
  message: () => {
    const minTransfer = 10000
    return `Minimal ${IDR(minTransfer)}`
  },
})

extend('maxTransfer', {
  validate: (value, args) => {
    const maxTransfer = args[0]
    const amount = parseFloat(value.replace(/[Rp.p]/g, ''))
    return amount <= maxTransfer
  },
  message: (field, args) => {
    const maxTransfer = args[0]
    return `Saldo yang bisa di transfer sejumlah ${IDR(maxTransfer)}`
  },
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VSelect,
    CodeInput,
  },
  props: {
    infoBalance: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isNumber,
      required,
      cardTransferId: null,
      cardId: this.$route.params.id,
      optionsCards: [],
      isLoading: false,
      isLoadingTransfer: false,
      isVisibility: true,
      visibilityPin: 'number',
      pin: '',
      amount: null,
      IDRWithoutLbl,
      IDR,
      errorMessage: '',
    }
  },
  computed: {
    balanceDifference() {
      return this.kompoints.balance - this.voucher.kompoint_fee
    },
  },
  mounted() {
    this.getListCard()
  },
  methods: {
    async getListCard() {
      this.isLoading = true
      const url = '/komcards/api/v1/card/list'
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res
          const tempData = data.data.map(item => ({
            ...item,
            value: item.id,
            card_name: `${item.label} *${this.splitCardNumber(item.card_number)}`,
          }))

          const filteredData = tempData.filter(item => item.id !== Number(this.cardId))
          this.optionsCards = filteredData
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.$toast_error({ message: 'Gagal mendapatkan daftar kartu' })
        })
    },
    async onSubmit() {
      this.isLoadingTransfer = true
      const url = `/komcards/api/v1/card/${this.cardId}/transfer-balance`
      const payload = {
        pin: this.pin,
        transfer_to: this.cardTransferId,
        amount: Number(this.amount.replace(/[Rp. ]/g, '')),
      }
      await newAxiosIns.post(url, payload)
        .then(() => {
          this.isLoadingTransfer = false
          this.$toast_success({ message: 'Saldo berhasil ditransfer' })
          this.$refs['modal-confirmation-transfer'].hide()
          this.amount = null
          this.cardTransferId = null
          this.pin = ''
          this.errorMessage = ''
        })
        .catch(err => {
          const { message } = err.response.data.meta
          this.isLoadingTransfer = false

          if (message === 'wrong pin numbers') {
            this.errorMessage = 'PIN yang anda masukan salah'
            return
          }

          if (message === 'pending transaction found, cannot proceed') {
            this.errorMessage = 'Transaksi sedang diproses. Mohon tunggu beberapa saat'
            return
          }
          this.$toast_error({ message: 'Terjadi kesalahan sementara. Mohon coba lagi dalam beberapa saat' })
        })
    },
    handleTransfer() {
      this.$refs['modal-confirmation-transfer'].show()
      this.$refs['modal-transfer'].hide()
      this.errorMessage = ''
    },
    handleBackToTransfer() {
      this.$refs['modal-confirmation-transfer'].hide()
      this.$refs['modal-transfer'].show()
      this.pin = ''
    },
    handleCloseModal() {
      this.$emit('getVoucherList')
      this.$emit('getKompoints')
      this.cardTransferId = null
    },
    visiblePin() {
      this.isVisibility = !this.isVisibility
      this.visibilityPin = this.isVisibility ? 'number' : 'text'
    },
    handleChangePin(value) {
      this.pin = value
    },
    splitCardNumber(number) {
      return number.split(' ').pop()
    },
    formatCurrency(value) {
      const currency = value.replace(/[Rp. ]/g, '')
      const format = new Intl.NumberFormat('id-ID').format(currency)
      this.amount = `Rp ${format}`
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
