<template>
  <div>
    <BAlert
      v-if="isCheckKyc"
      show
      variant="warning"
      class="p-2"
    >
      <div class="flex flex-row items-center justify-between gap-2">
        <div>
          <div class="flex items-center gap-3 mb-[10px]">
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/warning-Info-circle.svg"
              alt="Komerce"
            >
            <h4 class="text-black font-semibold m-0">
              {{ titleCheckKyc }}
            </h4>
          </div>
          <BCardText class="text-[#333333]">
            {{ messageCheckKyc }}
          </BCardText>
        </div>
        <BButton
          v-if="profile.verification_ktp_status === 0 || profile.verification_ktp_status === 3"
          size="sm"
          variant="outline-warning"
          @click="$router.push('/verification-komcard')"
        >
          {{ buttonKycBanner }}
        </BButton>
      </div>
    </BAlert>
    <BAlert
      v-if="size(dataCard) > minCard"
      show
      variant="warning"
      class="p-2"
      dismissible
    >
      <h4 class="text-black font-semibold">
        Informasi Pembuatan Kartu
      </h4>
      <template slot="dismiss">
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
          alt="Komerce"
          width="20"
          class="cursor-pointer"
        >
      </template>
      <BCardText class="text-[#333333]">
        {{ warningMessage }}
      </BCardText>
    </BAlert>
    <BCard>
      <BRow class="items-center justify-between pl-[15px] pr-[15px]">
        <div class="text-black font-semibold m-0 text-2xl">
          Kartu Debit
        </div>
        <BButton
          size="sm"
          :variant="size(dataCard) >= maxCard || isCheckKyc ? 'secondary' : 'primary'"
          style="display: flex; align-items: center; justify-content: space-between; padding: 5px 10px;"
          :disabled="loading.pin || loading.balance || size(dataCard) >= maxCard || isCheckKyc"
          @click="checkPin"
        >
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/card-add.svg"
            alt="Komerce"
            width="20"
          >
          <div class="font-semibold ml-[5px]">
            Tambah Kartu
          </div>
          <BSpinner
            v-if="loading.pin"
            variant="light"
            small
            class="ml-1"
          />
        </BButton>
      </BRow>
      <Card
        :data="dataCard"
        :loading-card="loading.card"
        :loading-pin="loading.pin"
        :loading-balance="loading.balance"
        :handle-topup="handleTopup"
        :get-list-cards="getListCard"
      />
      <SpendingCategoryVue />
      <BRow class="pl-[15px] pr-[15px] items-center justify-between">
        <div class="text-black font-semibold m-0 text-2xl">
          Mutasi
        </div>
        <div class="flex items-center flex-row">
          <BButton
            style="padding: 5px 1rem"
            variant="outline-primary"
            size="sm"
            @click="$bvModal.show('download-mutation-transaction-komcards')"
          >
            <div class="ml-[10px] mr-[10px] flex items-center">
              <b-img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-download.svg" />
              <span class="ml-[4px]">Download Mutasi</span>
            </div>
          </BButton>
          <div class="ml-1">
            <BButton
              id="filter"
              size="sm"
              variant="primary"
            >
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
                alt="Komerce"
                width="20"
              >
            </BButton>
            <BPopover
              target="filter"
              triggers="click"
              placement="bottomleft"
            >
              <label>Tanggal</label>
              <BRow>
                <BCol md="6">
                  <FlatPickr
                    v-model="startDate"
                    class="form-control"
                    placeholder="Mulai Dari"
                    :config="{ mode: 'single', altInput: true, altFormat: 'j/n/Y', dateFormat: 'Y-m-d',}"
                    @input="setStartDate"
                  />
                </BCol>
                <BCol md="6">
                  <FlatPickr
                    v-model="endDate"
                    class="form-control"
                    placeholder="Sampai Dengan"
                    :config="{ mode: 'single', altInput: true, altFormat: 'j/n/Y', dateFormat: 'Y-m-d', minDate: startDate}"
                    @input="setEndDate"
                  />
                </BCol>
              </BRow>
              <label class="mt-1">Jenis Transaksi</label>
              <VSelect
                v-model="transactionType"
                :options="optionsTransactionType"
                :reduce="(option) => option.value"
                label="name"
                @input="setTransactionType"
              >
                <span
                  slot="no-options"
                  @click="$refs.select.open = false"
                />
              </VSelect>
              <label class="mt-1">Nama Kartu</label>
              <VSelect
                v-model="cardId"
                :options="optionsCardId"
                :reduce="(option) => option.value"
                label="name"
              >
                <span
                  slot="no-options"
                  @click="$refs.select.open = false"
                />
              </VSelect>
              <BRow class="mx-auto mt-2">
                <BButton
                  variant="outline-primary"
                  class="mr-1"
                  @click.prevent="resetFilter()"
                >
                  Reset
                </BButton>
                <BButton
                  variant="primary"
                  @click.prevent="getListMutation()"
                >
                  Terapkan
                </BButton>
              </BRow>
            </BPopover>
          </div>
        </div>
      </BRow>
      <Mutation
        :scroll="'scroll'"
        :loading="loading.mutation"
        :items="listMutation"
        :fields="fields"
        :status-class="statusClass"
        :nominal-class="nominalClass"
      />

      <ModalCreatePin />
      <ModalInfoBalance
        :balance="balance"
        :min-saldo-kompay="minSaldoKompay"
      />
      <ModalTopup
        :card-data="cardData"
        :get-cards="getListCard"
        :get-mutation="getListMutation"
        :balance-data="balanceData"
      />

      <ModalDownloadMutasi :list-cards="dataCard" />
    </BCard>
  </div>
</template>

<script>
import { FULL_DATE_TIME, YEAR_MONTH_DAY } from '@/libs/formatDate'
import size from 'lodash/size'
import moment from 'moment'
import { komshipAxiosIns, newAxiosIns } from '@/libs/axios'
import FlatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import { columnConfig, settings, optionsTransactionType } from './config'
import {
  Card, Mutation, ModalCreatePin, ModalInfoBalance, ModalTopup, ModalDownloadMutasi,
} from './components'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'
// import 'vue-select/dist/vue-select.css'
import SpendingCategoryVue from './components/SpendingCategory.vue'

export default {
  components: {
    Card,
    Mutation,
    ModalCreatePin,
    ModalInfoBalance,
    ModalTopup,
    FlatPickr,
    VSelect,
    SpendingCategoryVue,
    ModalDownloadMutasi,
  },
  data() {
    return {
      fields: columnConfig,
      dataCard: [],
      listMutation: [],
      FULL_DATE_TIME,
      YEAR_MONTH_DAY,
      settings,
      showCardDetails: false,
      activeCard: null,
      loading: {
        card: false,
        mutation: false,
        pin: false,
        balance: false,
      },
      size,
      idPartner: this.$store.state.auth.userData.id,
      balance: null,
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      limit: 10,
      offset: 0,
      lastData: false,
      transactionType: '',
      optionsTransactionType,
      cardId: '',
      optionsCardId: [],
      selectedTransactions: [],
      maxCard: 0,
      minCard: 4,
      maxCardMessage: '',
      minCardMessage: 'Kamu telah membuat 5 kartu komcards. Biaya pembuatan kartu berikutnya adalah Rp. 20.000/kartu dengan saldo awal minimal Rp. 20.000. Periksa saldo kompaymu sebelum melanjutkan proses selanjutnya!',
      warningMessage: null,
      minTopUp: 40000,
      minSaldo: 20000,
      minSaldoKompay: null,
      cardData: {},
      showModalTopup: false,
      balanceData: null,
      profile: {},
    }
  },
  computed: {
    setStartDate() {
      return value => {
        this.startDate = value
      }
    },
    setEndDate() {
      return value => {
        this.endDate = value
      }
    },
    setTransactionType() {
      return value => {
        this.transactionType = value
      }
    },
    statusClass() {
      return status => {
        if (status === 'success') {
          return 'text-[#34A770]'
        }
        if (status === 'failed') {
          return 'text-[#E31A1A]'
        }
        if (status === 'pending') {
          return 'text-[#828282]'
        }
        return ''
      }
    },
    nominalClass() {
      return type => {
        if (type === 'credit') {
          return 'text-[#34A770]'
        }
        if (type === 'debit') {
          return 'text-[#E31A1A]'
        }
        if (type === 'cashback') {
          return 'text-[#CC9212]'
        }
        return ''
      }
    },
    resetFilter() {
      return () => {
        this.startDate = moment().startOf('month').format('YYYY-MM-DD')
        this.endDate = moment().format('YYYY-MM-DD')
        this.transactionType = ''
        this.cardId = ''
      }
    },
    handleTopup() {
      return card => {
        this.cardData = card
        this.showModalTopup = true
        this.getBalance()
      }
    },
    isCheckKyc() {
      if (this.dataCard.length >= 1) {
        if (this.profile.verification_ktp_status === 0
        || this.profile.verification_ktp_status === 1
        || this.profile.verification_ktp_status === 3) {
          return true
        }
      }
      return false
    },
    titleCheckKyc() {
      if (this.profile.verification_ktp_status === 0) {
        return 'Verifikasi KTP'
      }
      if (this.profile.verification_ktp_status === 1) {
        return 'Verifikasi KTP dalam proses persetujuan'
      }
      if (this.profile.verification_ktp_status === 3) {
        return 'Verifikasi KTP ditolak'
      }
      return null
    },
    messageCheckKyc() {
      if (this.profile.verification_ktp_status === 0) {
        return 'Setelah verifikasi berhasil, kamu juga bisa membuat kartu Komcards lagi'
      }
      if (this.profile.verification_ktp_status === 1) {
        return 'Maaf kamu belum bisa membuat kartu baru karena sedang dalam proses persetujuan admin'
      }
      if (this.profile.verification_ktp_status === 3) {
        return 'Pengajuan verifikasi KTP ditolak, silahkan ajukan verifikasi ulang untuk bisa membuat kartu baru'
      }
      return null
    },
    buttonKycBanner() {
      if (this.profile.verification_ktp_status === 0) {
        return 'Verifikasi Sekarang'
      }
      if (this.profile.verification_ktp_status === 3) {
        return 'Verifikasi Ulang'
      }
      return null
    },
  },
  created() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('scroll').offsetHeight && !this.loading.mutation) {
        this.getNextDataMutation()
      }
    }
  },
  mounted() {
    this.getListCard()
    this.getListMutation()
    this.getProfile()
  },
  methods: {
    async getListCard() {
      this.loading.card = true
      const url = '/komcards/api/v1/card/list'
      await newAxiosIns.get(url)
        .then(res => {
          const { data, max_quota_card } = res.data
          // eslint-disable-next-line camelcase
          this.maxCard = max_quota_card
          this.maxCardMessage = `Batas maksimal Kartu Komcards yang bisa kamu buat adalah ${this.maxCard} kartu dan kamu telah mencapai batas maksimum untuk pembuatan Kartu Komcards.`
          this.dataCard = data
          if (data.length > this.minCard && data.length < this.maxCard) {
            this.warningMessage = this.minCardMessage
          }
          if (data.length === this.maxCard) {
            this.warningMessage = this.maxCardMessage
          }
          this.loading.card = false
        })
        .catch(() => {
          this.loading.card = false
          this.$toast_error({ message: 'Gagal memuat data kartu, silahkan coba lagi' })
        })
    },
    async getListMutation() {
      this.loading.mutation = true
      this.offset = 0
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        transaction_type: this.transactionType,
        card_id: this.cardId,
        offset: this.offset,
        limit: this.limit,
      }
      const url = '/komcards/api/v1/card/mutation'
      await newAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.listMutation = data
          this.loading.mutation = false

          const debitCard = [
            {
              value: '',
              name: 'Semua',
            },
            ...this.dataCard.map(card => ({
              value: card.id,
              name: card.label,
            })),
          ]
          this.optionsCardId = debitCard
          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(() => {
          this.loading.mutation = false
          this.$toast_error({ message: 'Gagal load data balance, silahkan coba lagi' })
        })
    },
    async checkPin() {
      this.loading.pin = true
      const url = '/v1/pin/check'
      await komshipAxiosIns.get(url)
        .then(res => {
          this.loading.pin = false
          const { data: { is_set } } = res.data

          // eslint-disable-next-line camelcase
          if (is_set === false) {
            this.$bvModal.show('modal-check-pin')
          } else {
            this.checkBalance()
          }
        })
        .catch(() => {
          this.loading.pin = false
          this.$toast_error({ message: 'Gagal check pin, silahkan coba lagi' })
        })
    },
    async checkBalance() {
      this.loading.balance = true
      const url = `/v1/dashboard/partner/balanceSummary?partner_id=${this.idPartner}`
      await komshipAxiosIns.get(url)
        .then(res => {
          const { data: { balance } } = res.data
          this.balance = balance
          this.loading.balance = false
          if (balance < this.minSaldo && this.dataCard.length < 5) {
            this.$bvModal.show('modal-info-balance')
            this.minSaldoKompay = this.minSaldo
          } else if (balance < this.minTopUp && this.dataCard.length >= 5) {
            this.$bvModal.show('modal-info-balance')
            this.minSaldoKompay = this.minTopUp
          } else {
            this.$router.push({ path: '/komcards/add', query: { count: this.dataCard.length } })
          }
        })
        .catch(() => {
          this.loading.balance = false
          this.$toast_error({ message: 'Gagal load data balance, silahkan coba lagi' })
        })
    },
    async getBalance() {
      const url = `/v1/dashboard/partner/balanceSummary?partner_id=${this.idPartner}`
      await komshipAxiosIns.get(url)
        .then(res => {
          const { data: { balance } } = res.data
          this.balanceData = balance
          if (this.showModalTopup === true) {
            this.$bvModal.show('modal-topup')
          }
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal load data balance, silahkan coba lagi' })
        })
    },
    async getProfile() {
      const url = '/v1/my-profile'
      await komshipAxiosIns.post(url)
        .then(res => {
          const { data } = res.data
          this.profile = data
        })
    },
    getNextDataMutation() {
      if (!this.lastData) {
        this.loading.mutation = true
        const params = {
          start_date: this.startDate,
          end_date: this.endDate,
          transaction_type: this.transactionType,
          card_id: this.cardId,
          offset: this.offset,
          limit: this.limit,
        }
        const url = '/komcards/api/v1/card/mutation'
        newAxiosIns.get(url, { params })
          .then(res => {
            const { data } = res.data
            this.loading.mutation = false
            this.listMutation.push(...data)
            this.offset += data.length
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(() => {
            this.loading.mutation = false
            this.$toast_error({ message: 'Gagal load data balance, silahkan coba lagi' })
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped src="./Komcard.scss" />
