import { IDR } from '@/libs/currency'

export const columnConfig = [
  {
    key: 'description',
    label: 'Deskripsi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      textAlign: 'center',
      minWidth: '150px',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'status',
    label: 'Status',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
    tdClass: 'text-black',
    class: 'p-1 text-center',
  },
  {
    key: 'date',
    label: 'Waktu',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      minWidth: '200px',
    },
    tdClass: 'text-black',
    class: 'p-1 text-center',
  },
  {
    key: 'card',
    label: 'Kartu Debit',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      minWidth: '200px',
    },
    tdClass: 'text-black',
    class: 'p-1 text-start',
  },
  {
    key: 'amount',
    label: 'Nominal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      minWidth: '150px',
    },
    tdClass: 'text-black',
    class: 'p-1 text-center text-center',
  },
]

export const columnConfigDetail = [
  {
    key: 'description',
    label: 'Deskripsi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      textAlign: 'center',
      minWidth: '150px',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'status',
    label: 'Status',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
    tdClass: 'text-black',
    class: 'p-1 text-center',
  },
  {
    key: 'date',
    label: 'Waktu',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      minWidth: '200px',
    },
    tdClass: 'text-black',
    class: 'p-1 text-center',
  },
  {
    key: 'amount',
    label: 'Nominal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      minWidth: '150px',
    },
    tdClass: 'text-black',
    class: 'p-1 text-center text-center',
  },
]

export const settings = {
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: true,
  touchThreshold: 5,
  swipeToSlide: true,
  swipe: true,
  responsive: [
    {
      breakpoint: 1201,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 820,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

export const listTopUp = [
  { id: 1, balance: 20000 },
  { id: 2, balance: 50000 },
  { id: 3, balance: 100000 },
  { id: 4, balance: 200000 },
  { id: 5, balance: 300000 },
  { id: 6, balance: 500000 },
  { id: 7, balance: 1000000 },
]

export const optionsTransactionType = [
  { value: '', name: 'Semua' },
  { value: 'credit', name: 'Transaksi Masuk' },
  { value: 'debit', name: 'Transaksi Keluar' },
  { value: 'cashback', name: 'Cashback' },
]

export const rules = [
  {
    id: 1,
    status: true,
    icon: 'check-circle-fill',
    image: [
      {
        id: 1,
        url:
          'https://storage.googleapis.com/komerce/assets/illustration/photo-selfie-ktp.jpg',
        width: 100,
      },
      {
        id: 2,
        url:
          'https://storage.googleapis.com/komerce/assets/illustration/sample-ktp.jpg',
        width: 100,
      },
    ],
    bodyClass: 'bg-[#DCF3EB] rounded-md',
    classStatus: 'text-emerald-700 ml-1',
    classTitle: 'text-emerald-700 font-medium mt-1',
    classLabel: 'font-normal text-emerald-600',
    variant: 'success',
    title: 'Status verifikasi KTP diterima apabila :',
    main_rules: [
      { id: 1, label: 'Selfie + KTP terfoto dengan jelas dan tidak terpotong' },
      {
        id: 2,
        label: 'Data yang ada di profile sesuai dengan data yang ada di KTP',
      },
    ],
  },
  {
    id: 2,
    status: false,
    icon: 'x-circle-fill',
    image: [
      {
        id: 1,
        url:
          'https://storage.googleapis.com/komerce/assets/illustration/photo-selfie-ktp-2.jpg',
        width: 100,
      },
      {
        id: 2,
        url:
          'https://storage.googleapis.com/komerce/assets/illustration/sample-ktp-blur.jpg',
        width: 100,
      },
    ],
    bodyClass: 'bg-[#FFEDED] rounded-md',
    classStatus: 'text-rose-700 ml-1',
    classTitle: 'text-rose-700 font-medium mt-1',
    classLabel: 'font-normal text-rose-600',
    variant: 'danger',
    title: 'Status verifikasi KTP ditolak apabila :',
    main_rules: [
      { id: 1, label: 'Selfie + KTP terfoto tidak jelas dan terpotong' },
      {
        id: 2,
        label:
          'Data yang ada di profile tidak sesuai dengan data yang ada di KTP',
      },
    ],
  },
]

export const getLabelStatus = value => {
  if (value === 'requested') return 'Request'
  if (value === 'approved') return 'Disetujui'
  if (value === 'rejected') return 'Ditolak'
  return ''
}

export const getClassStatus = value => {
  if (value === 'requested') return 'text-[#FBA63C]'
  if (value === 'approved') return 'text-[#34A770]'
  if (value === 'rejected') return 'text-[#E31A1A]'
  return ''
}

export const chartSpendingCategoryOptions = {
  chart: {
    type: 'donut',
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: 'right',
    fontSize: '14px',
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  // responsive: [
  //   {
  //     breakpoint: 480,
  //     options: {
  //       chart: {
  //         width: 230,
  //       },
  //       legend: {
  //         position: 'bottom',
  //       },
  //     },
  //   },
  //   {
  //     breakpoint: 768,
  //     options: {
  //       chart: {
  //         width: 400,
  //       },
  //       legend: {
  //         position: 'right',
  //       },
  //     },
  //   },
  //   {
  //     breakpoint: 1201,
  //     options: {
  //       chart: {
  //         width: 400,
  //       },
  //       legend: {
  //         position: 'right',
  //       },
  //     },
  //   },
  //   {
  //     breakpoint: 1441,
  //     options: {
  //       chart: {
  //         width: 400,
  //       },
  //       legend: {
  //         position: 'right',
  //       },
  //     },
  //   },
  // ],
  tooltip: {
    custom: opts => {
      const data = opts.w.config.series[opts.seriesIndex]
      const name = opts.w.config.labels[opts.seriesIndex]
      const amount = opts.w.config.xaxis.categories[opts.seriesIndex]

      const htmlEmptyData = '<div class="w-[200px] p-2 bg-white text-black">Belum ada data</div>'
      const htmlData = `<div class="w-[200px] p-2 bg-white text-black">
        <div class="flex items-center justify-between">
          <div class="text-[#828282] font-normal">${name}</div>
          <div class="border-left p-[2px] text-primary">${data}%</div>
        </div>
        <div v-if="data !== 1" class="font-bold">${amount}</div>
      </div>
      `
      return name !== 'Belum ada data' ? htmlData : htmlEmptyData
    },
  },
}
